import { defineStore } from 'pinia';
import { getMessages, sendMessage } from 'src/api/messages';
import { getThreads, readThread } from 'src/api/threads';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import {useUserStore} from "stores/user";

export const useThreadStore = defineStore('thread', {
  state: () => ({
    subjects: [],
    selectedSubjectIdx: null,
    selectedThreadId: null,
    openedThread: null,
    messages: [],
    threadsMessage: new Map(),
    messagesPageCursor: "",
    scrollKey: 0,
    isTyping: null,
    isSending: false,
    archivedStatus: 'u',
    usersSeenThread: []
  }),

  getters: {
    getSubjects(state) {
      return state.subjects;
    },
    getSelectedSubjectIdx(state) {
      return state.selectedSubjectIdx;
    },
    getSelectedThreadId(state) {
      return state.selectedThreadId;
    },
    getOpenedThread(state) {
      return state.openedThread;
    },
    getMessages(state) {
      return state.messages;
    },
    getThreadsMessage(state) {
      return state.threadsMessage;
    },
    getScrollKey(state) {
      return state.scrollKey;
    },
    getIsTyping(state) {
      return state.isTyping;
    },
    getIsSending(state) {
      return state.isSending;
    },
    getArchivedStatus(state) {
      return state.archivedStatus;
    },
    getSeenUsers(state) {
      return state.usersSeenThread;
    },
    getMessagesPageCursor(state) {
      return state.messagesPageCursor;
    }
    },

  actions: {
    async InitThreads(newCompanyId = null, oldCompanyId = null, archived = 'u') {
      this.selectedThreadId = null;
      this.archivedStatus = archived;

      if (archived === 'a') {
        const { data } = await getThreads('a');
        this.subjects = data;
        this.selectedSubjectIdx = 0;
      } else {
        const { data } = await getThreads('u');
        this.subjects = data;
        this.selectedSubjectIdx = null;
      }

      if (oldCompanyId !== newCompanyId) {
        // leave previous company threads channel
        if (oldCompanyId) {
          // close websocket connections
          window.Echo.leave(`companies.${oldCompanyId}`);
        }
        // connect to new company threads channel
        if (newCompanyId) {
          window.Echo.private(`companies.${newCompanyId}`)
            .listen('.ThreadTreeActivity', (e) => {
              this.InsertOrUpdateThread(e);
            });

          window.Echo.private(`companies.${newCompanyId}`)
            .listen('.ThreadCreatedActivity', (e) => {
              this.InsertOrUpdateThread(e);
            });
        }
      }
    },
    async SetSelectedThreadId(id, index = null) {
      if (this.selectedThreadId) {
        window.Echo.leave(`threads.${this.selectedThreadId}`);
      }

      this.messages = [];
      this.messagesPageCursor = "";

      // if the user selected the thread from UI we have both the id and the index and the subject Idx
      this.selectedThreadId = id;
      let selectedThreadSubjectIdx = this.selectedSubjectIdx;
      let selectedThreadIdx = index;

      if (id) {
        // if user selected thread from a different UI or from router
        // we have the thread id but we don't have the index
        // so we search for it and the subject index the thread belongs to
        // then we set both the subject index and the thread index
        if (index === null) {
          const subjectIdx = this.subjects ? this.subjects.findIndex(subject => {
            const idx = subject.threads.findIndex(thread => thread.id === id);
            if (idx >= 0) {
              selectedThreadIdx = idx;
              return true;
            } else {
              selectedThreadIdx = null;
              return false;
            }
          }) : -1;

          if (subjectIdx >= 0) {
            selectedThreadSubjectIdx = subjectIdx;
          } else {
            selectedThreadSubjectIdx = null
          }

          this.selectedSubjectIdx = selectedThreadSubjectIdx;
        }

        // if the subject idx is valid and the thread idx is valid
        // we connect to the messaging of the thread
        // else either the thread was moved to archived or removed entirely
        if (selectedThreadSubjectIdx !== null && selectedThreadIdx !== null) {
          this.openedThread = {
            name: this.subjects[selectedThreadSubjectIdx].name,
            threadable_url: this.subjects[selectedThreadSubjectIdx].threadable_url,
            extension: this.subjects[selectedThreadSubjectIdx].extension,
            directory: this.subjects[selectedThreadSubjectIdx].directory,
            thread: this.subjects[selectedThreadSubjectIdx].threads[selectedThreadIdx],
          };

          if (!this.threadsMessage.get(id)) {
            this.threadsMessage.set(id, { message: ref(''), files: ref([]) });
          }

          await this.FetchInitialMessages(id, selectedThreadSubjectIdx, selectedThreadIdx);

          window.Echo.private(`threads.${id}`)
            .listen('.MessagesActivity', e => {
              const newMessage = e.message;
              this.messages = [newMessage, ...this.messages];

                readThread(id)
                    .then((response) => {
                      this.subjects[selectedThreadSubjectIdx].threads[selectedThreadIdx].unread = 0;
                      this.UpdateScrollKey();
                    });
            });

          window.Echo.private(`threads.${id}`)
            .listenForWhisper('typing', (e) => {
              if (e.characters > 0) {
                this.isTyping = id;
              } else {
                this.isTyping = null;
              }
            });

          window.Echo.private(`threads.${id}`)
              .listen('.ThreadSeenActivity', (event) => {
                this.usersSeenThread = Array.isArray(event.thread_seens) ? event.thread_seens : [];
                this.UpdateScrollKey();
          });

        } else {
          this.openedThread = null;
        }
      }
    },
    SetSelectedSubjectIdx(idx) {
      this.selectedSubjectIdx = idx;
    },
    InsertOrUpdateThread(newSubject) {
      if (newSubject) {

        const subjectIndex = this.subjects ? this.subjects.findIndex((subject) => subject.name === newSubject.name && subject.attachment_id == newSubject.attachment_id) : -1 ;
        if (subjectIndex !== -1) {
          const subject = this.subjects.at(subjectIndex);
          let threadIndex = subject.threads.findIndex((subject) => subject.id == newSubject.threads.at(0).id);
          if (threadIndex !== -1) {
            subject.threads[threadIndex] = newSubject.threads.at(0);
          } else {
            threadIndex = subject.threads.push(newSubject.threads.at(0)) - 1;
          }
          this.ReorderThreadsTree(subjectIndex, threadIndex);
        } else {
          this.subjects.push(newSubject);
        }
      }
    },
    ReorderThreadsTree(subjectIndex, threadIndex) {
      let newSubjectIdx;
      const targetSubject = this.subjects.splice(subjectIndex, 1).at(0);
      if (this.subjects.at(0) && this.subjects.at(0).name === 'general') {
        const generalSubject = this.subjects.splice(0, 1).at(0);
        this.subjects = [generalSubject, targetSubject, ...this.subjects];
        newSubjectIdx = 1;

      } else {
        this.subjects = [targetSubject, ...this.subjects];
        newSubjectIdx = 0;
      }

      if (this.selectedSubjectIdx === subjectIndex) {
        this.SetSelectedSubjectIdx(newSubjectIdx);
      }

      const targetThread = targetSubject.threads.splice(threadIndex, 1).at(0);
      targetSubject.threads = [targetThread, ...targetSubject.threads];
    },
    async FetchInitialMessages(id, subjectIdx, threadIdx) {
      const { data } = await getMessages(id)

      this.messages = data.messages;
      this.messagesPageCursor = data.pagination.cursor;
      this.usersSeenThread = data.thread_seens;


      readThread(id)
        .then(() => {
          this.subjects[subjectIdx].threads[threadIdx].unread = 0;
          this.UpdateScrollKey();
        });
    },
    FetchMoreMessages(done) {
      if (!this.messagesPageCursor) {
        done();
        return;
      }

      getMessages(this.selectedThreadId, this.messagesPageCursor)
        .then(({ data }) => {
          this.messages = this.messages.concat(data.messages);
          this.messagesPageCursor = data.pagination.cursor;
          this.usersSeenThread = data.thread_seens;
          done();
        });
    },
    SendMessage() {
      this.isTyping = null
      window.Echo.private(`threads.${this.selectedThreadId}`)
        .whisper('typing', { characters: 0 });

      if (this.threadsMessage.get(this.selectedThreadId).message || this.threadsMessage.get(this.selectedThreadId).files.length) {

        this.isSending = true;

        const messageForm = new FormData();
        messageForm.append('content', this.threadsMessage.get(this.selectedThreadId).message);
        for (let file of this.threadsMessage.get(this.selectedThreadId).files) {
          messageForm.append('attachments[]', file);
        }
        sendMessage(this.selectedThreadId, messageForm)
          .then(({ status, data }) => {
            if (status) {
              this.threadsMessage.get(this.selectedThreadId).message = '';
              this.threadsMessage.get(this.selectedThreadId).files = [];
              this.UpdateScrollKey();
              data.threads[0].unread = 0;
              this.isSending = false;
              this.InsertOrUpdateThread(data);
            }
          });
      }
    },
    UpdateScrollKey() {
      this.scrollKey++;
    },
    CleanStore() {
      this.selectedThread = null;
      this.subjects = [];
      this.archivedSubjects = [];
    }
  }
})
